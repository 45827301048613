import * as React from "react"
import ContentPageLayout from "../../components/ContentPageLayout";
import Seo from "../../components/seo";
import CheckoutForm from "../../components/forms/payments/checkoutForm";
import NewCardForm from "../../components/forms/payments/newCard";
import PaymentMethodForm from "../../components/forms/payments/paymentMethods";
import { firebaseInit } from "../../config";
import firebase from "firebase"
import { getMobilePayments, getPaymentInfo } from "../../api/content";


// const IndexPageCheckOut = ({ props, token, onCardAdded }) => {
const IndexPageCheckOut = ({ ...props }) => {


    const [paymentForm, setPaymentForm] = React.useState("checkout")
    const [paymentInfo, setPaymentInfo] = React.useState({
        paymentMethods: [],
    })
    const [token, setToken] = React.useState("")
    const [error, setError] = React.useState("")

    const [reference, setReference] = React.useState()
    const [code, setCode] = React.useState()

    const [membershipByCode, setMembershipByCode] = React.useState(null)


    React.useEffect(() => {

        let qs = props.location.search
        let queries = qs.split('?')
        let refQs = undefined

        if (queries.length > 1) {
            queries[1].split('&').map(query => {
                if (query.includes('ref')) {
                    refQs = query.split('=')[1]
                    setReference(query.split('=')[1])
                }
                if (query.includes('code')) {
                    setCode(query.split('=')[1])
                }
            })
            if (refQs) {
                getPaymentInfo(refQs)
                    .then(response => {
                        if (response) {
                            console.log(response.codigos)
                            setMembershipByCode(response)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }

    }, [])

    React.useEffect(() => {
        console.log("status useEffect call")
        firebaseInit(firebase)
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user
                    .getIdToken(true)
                    .then(token => {
                        setToken(token)
                        getMobilePayments(token)
                            .then(result => {
                                setPaymentInfo(result)
                                // setLoading(false)
                            })
                            .catch(error => {
                                setError(
                                    "No se ha podido cargar tu perfil de pagos intenta nuevamente."
                                )
                                // setLoading(false)
                            })
                        // setLoading(false)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                console.log("user is null")
            }
        })
        console.log(token)
    }, [])


    const handleMethodChange = card => {
        console.log("selected method ")
        console.log(card)
        setPaymentForm("checkout")
        setPaymentInfo({ ...paymentInfo, defaultMethod: card })
    }
    const handleCardAdded = card => {
        setPaymentInfo({
            ...paymentInfo,
            paymentMethods: [card, ...paymentInfo.paymentMethods],
        })
        setPaymentForm("paymentMethods")
    }

    const handleMethodDelete = card => {
        console.log("metod deleted")
        console.log(card)
        console.log("current methods")
        console.log(paymentInfo.paymentMethods)
        let filteredMethods = paymentInfo.paymentMethods.filter(
            item => item.id !== card.id
        )
        console.log("new methods")
        console.log(filteredMethods)
        setPaymentInfo({ ...paymentInfo, paymentMethods: filteredMethods })
    }


    return (
        <ContentPageLayout>
            <Seo title='Checkout' />
            <div className="w-4/6 mx-auto mt-8 pb-24">
                {paymentForm === "checkout" ? (
                    <CheckoutForm
                        code={code}
                        reference={reference}
                        location={""}
                        onEditMethodRequest={() => {
                            setPaymentForm("paymentMethods")
                        }}
                        token={token}
                        onSuccessSuscription={suscription => {
                            setPaymentInfo({ ...paymentInfo, suscribed: true })
                        }}
                        paymentInfo={paymentInfo}
                        membershipByCode={membershipByCode} />
                ) : null}
                {paymentForm === "paymentMethods" ? (
                    <PaymentMethodForm
                        token={token}
                        onDeleteMethod={handleMethodDelete}
                        paymentMethods={paymentInfo.paymentMethods}
                        selectedMethod={paymentInfo.defaultMethod}
                        goBack={() => {
                            setPaymentForm("checkout")
                        }}
                        onChangeMethod={handleMethodChange}
                        onAddCardRequest={() => {
                            setPaymentForm("newCard")
                        }}
                    />
                ) : null}
                {paymentForm === "newCard" ? (
                    <NewCardForm
                        location={""}
                        goBack={() => {
                            setPaymentForm("paymentMethods")
                        }}
                        onCardAdded={handleCardAdded}
                        token={token}
                    ></NewCardForm>
                ) : null}
            </div>
        </ContentPageLayout>
    )
}

export default IndexPageCheckOut